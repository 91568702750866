import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modalremoval"
export default class extends Controller {

  static targets = ["close"]

  connect() {
    document.addEventListener("click", (e)=>{
      if(e.target.className == "modal"){
        this.element.remove();
      }
    })
  }

  close() {
    this.element.remove();
  }
}
