export default () => {
  window.addEventListener('load', () => {
    submitBtnHandling();
  })

  let turboFrames = document.querySelectorAll('turbo-frame');
  // console.log(turboFrames)

  const observer = new MutationObserver(() => {
    submitBtnHandling();    
  })

  turboFrames.forEach(frame => {
    observer.observe(frame, {subtree: true, childList: true});
  });

  const submitBtnHandling = () => {
    let submitBtns = document.getElementsByClassName('btn--primary');
    [...submitBtns].forEach(btn => {
      if(btn.type == "submit"){
        btn.addEventListener("click", () => {
          document.body.classList.add('loading')
        })
      }
    });
  }
  
  document.addEventListener('turbo:submit-start', () => {
    document.body.classList.add('loading')
    // console.log('Submitting');
  });

  document.addEventListener('turbo:submit-end', () => {
    document.body.classList.remove('loading')
    // console.log('Finished Submitting');
  });

};